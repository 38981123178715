body {
  background-color: #ffffff;
}

.navbar-bg {
  background-color: #f7d417;
}

.max-width {
  max-width: 400px;
}

.card {
  margin-top: 100px;
}

.page-item:hover {
  transform: scale(1.05);
  transition: 1s;
}

.list {
  position: relative;
  width: 110%;
  margin-top: 100px;
  /* (width - 100)/2 */
  left: -5%;
}

.pagination > li > a {
  background-color: white;
  color: #0075a3;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: #0075a3;
  background-color: #eee;
  border-color: #ddd;
}

.pagination > .active > a {
  color: white;
  background-color: #0075a3 !important;
  border: solid 1px #0075a3 !important;
}

.pagination > .active > a:hover {
  background-color: #0075a3 !important;
  border: solid 1px #0075a3;
}

.page-item.active .page-link {
  background-color: #0075a3 !important;
}

/* use the media query to figure out what to set the min height for */
@media only screen and (max-width: 1450px) {
  .list {
    margin-top: 100px;
    left: 0%;
    width: 100%;
  }
}
